<template>
    <admin-layout>
        <visible-for role="admin">
            <h1 class="fw-bold text-center mb-3">
                Používatelia
            </h1>
            <div class="d-flex justify-content-center text-center mb-4">
                <router-link :to="{name: 'users.create'}" class="btn btn-success fw-bold mx-1 px-3">
                    Pridať používateľa
                </router-link>
                <submit-button :disabled="exportFetching" :fetching="exportFetching" class="mx-1" color="primary" title="Export používateľov" type="button" @click.native="exportUsers"></submit-button>
                <div class="mx-1 position-relative">
                    <input :disabled="fetching" @keyup="searchInputChange" ref="search" class="form-control d-inline-block" placeholder="Vyhľadávanie" type="search">
                    <div class="position-absolute top-50 translate-middle-y" style="right: 8px">
                        <span v-if="searchFetching" class="spinner-border spinner-border-sm text-primary" role="status"></span>
                        <span style="cursor: pointer" @click.prevent="clearSearchInput" v-else-if="this.searchValue"><i class="bi bi-x"></i></span>
                    </div>
                </div>
            </div>
            <loader v-if="fetching"></loader>
            <div v-else class="table-responsive">
                <table class="table table-bordered table-hover align-middle bg-white">
                    <thead class="table-dark">
                        <tr class="fw-bold">
                            <th scope="col">Meno</th>
                            <th scope="col">Priezvisko</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Kurzy</th>
                            <th class="text-center" scope="col">Jazyk</th>
                            <th class="text-center" scope="col">Rola</th>
                            <th class="text-center" scope="col">Akcia</th>
                        </tr>
                    </thead>
                    <tbody>
                        <user-list-table-row v-for="user in data" :key="user.id" :user="user"></user-list-table-row>
                    </tbody>
                </table>
            </div>
            <nav>
                <ul class="pagination" style="overflow-x: scroll">
                    <li v-for="index of this.pagination.lastPage" class="page-item" :class="{active: index === currentPage}" @click.prevent="changePage(index)">
                        <a class="page-link" href="#">{{ index }}</a>
                    </li>
                </ul>
            </nav>
        </visible-for>
    </admin-layout>
</template>

<script>
import AdminLayout from '../layout/AdminLayout'
import VisibleFor from '../component/VisibleFor'
import UserListTableRow from '../component/UserListTableRow'
import Loader from '../component/Loader'
import { isEmpty } from 'ramda'
import SubmitButton from '../component/SubmitButton'
import { api } from '@/store/api'
import { toast } from '@/util/toast'
import { format } from 'date-fns'
import { clamp, debounce } from 'lodash-es'

export default {
    beforeDestroy () {
        if (typeof this.searchUsers?.cancel === 'function') {
            this.searchUsers.cancel()
        }
    },
    components: { SubmitButton, Loader, UserListTableRow, VisibleFor, AdminLayout },
    computed: {
        data () {
            if (! isEmpty(this.searchResults)) {
                return this.searchResults
            }
            
            if (isEmpty(this.searchResults) && ! isEmpty(this.searchValue) && ! this.searchFetching) {
                return []
            }
            
            return this.$store.getters['user/selectUsers']
        },
        fetching () {
            return this.$store.getters['user/isFetching']
        },
        pagination () {
            if (! isEmpty(this.searchResults) && ! isEmpty(this.searchValue)) {
                return this.searchPagination
            }
            
            return this.$store.getters['user/selectPagination']
        },
    },
    data () {
        return {
            abortController: null, //new AbortController(),
            currentPage: 1,
            exportFetching: false,
            searchFetching: false,
            searchResults: [],
            searchPagination: {},
            searchValue: '',
        }
    },
    methods: {
        changePage (page) {
            this.currentPage = page
        },
        clearSearchInput () {
            this.$refs.search.value = ''
            this.searchInputChange({ target: { value: '' } })
        },
        exportUsers (event) {
            event?.preventDefault()
            
            this.exportFetching = true
            
            api.user.export()
               .then(response => {
                   const fileLink = document.createElement('a')
                   
                   fileLink.href = URL.createObjectURL(response?.data)
                   fileLink.setAttribute('download', `pouzivatelia-${format(new Date(), 'yyyy-MM-dd')}.xlsx`)
                   
                   document.body.appendChild(fileLink)
                   
                   fileLink.click()
                   fileLink.remove()
               })
               .catch(() => toast.danger('Export používateľov', 'Export sa nepodarilo vygenerovať alebo stiahnuť'))
               .finally(() => this.exportFetching = false)
        },
        searchInputChange: function (event) {
            this.searchFetching = true
            
            const value = (event.target.value ?? '').trim()
            
            this.searchValue = value
            
            if (this.abortController) {
                this.abortController.abort()
            }
            
            if (isEmpty(value)) {
                this.searchFetching = false
                this.searchResults = []
                
                return
            }
            
            this.abortController = new AbortController()
            this.searchUsers(value, this.abortController.signal)
        },
        searchUsers: debounce(function (value = '', signal) {
            api.user.list({ search: value?.split(' ') }, signal)
               .then(response => {
                   this.searchResults = response?.data?.data
                   this.searchPagination = {
                       lastPage: response?.data?.meta?.last_page,
                       perPage: response?.data?.meta?.per_page,
                   }
                   this.currentPage = clamp(this.currentPage, 1, response?.data?.meta?.current_page)
               })
               .catch(() => {
                   toast.clear()
                   // toast.danger('Nepodarilo sa vyhľadať používateľa')
                   this.searchResults = []
                   this.searchPagination = {
                       lastPage: 1,
                       perPage: 50,
                   }
               })
               .finally(() => {
                   this.searchFetching = false
               })
        }, 1000),
    },
    name: 'UserList',
    watch: {
        currentPage (newValue) {
            if (this.abortController) {
                this.abortController.abort()
            }
            
            this.abortController = new AbortController()
            
            const params = {
                page: newValue,
            }
            
            if (! isEmpty(this.searchValue)) {
                params.search = this.searchValue.split(' ')
            }
            
            api.user.list(params, this.abortController.signal)
               .then(response => {
                   this.searchResults = response?.data?.data
                   this.currentPage = clamp(this.currentPage, 1, response?.data?.meta?.current_page)
               })
            // console.log('new page value', newValue)
        },
    },
}
</script>


<style lang="scss" scoped>
table {
    tr {
        td, th {
            &:last-child {
                width: 200px;
            }
        }
    }
}
</style>
