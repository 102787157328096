<template>
    <tr>
        <td>{{ user.first_name }}</td>
        <td>{{ user.last_name }}</td>
        <td>
            <a :href="`mailto:${user.email}`">
                {{ user.email }}
            </a>
        </td>
        <td>
            <ul class="list-unstyled m-0 p-0">
                <li v-for="pkg in user.packages" :key="pkg.id">
                    <router-link v-if="pkg.course" :to="{name: 'courses.detail', params: {courseId: pkg.course.id}}" class="me-2">
                        {{ pkg.course.title }}
                    </router-link>
                    <span v-if="pkg.pivot.expire_at">
                        ({{ expiration(pkg.pivot.expire_at) }})
                    </span>
                </li>
            </ul>
        </td>
        <td class="text-center">{{ user.language.toUpperCase() }}</td>
        <td class="text-center">{{ translate(user.role.id) }}</td>
        <td>
            <div class="d-flex justify-content-center align-items-center">
                <router-link :to="{name: 'users.update', params: {userId: user.id}}" class="btn btn-warning mx-1">
                    <i class="bi bi-pencil-fill"></i>
                </router-link>
                <button v-if="canDelete" class="btn btn-danger mx-1" type="button" @click.prevent="deleteUser">
                    <i class="bi bi-trash-fill"></i>
                </button>
            </div>
        </td>
    </tr>
</template>

<script>
import { translateKey } from '@/util/translateKey'
import { courseExpiration } from '@/util/courseExpiration'
import { isAfter, parseISO } from 'date-fns'

export default {
    beforeDestroy () {
        clearInterval(this.timer)
    },
    computed: {
        canDelete () {
            return this.user.id !== this.$store.getters['auth/selectUserId']
        },
        courses () {
            return this.$store.getters['user/selectUserCourses'](this.user.id)
        },
        fullName () {
            return `${this.user.first_name} ${this.user.last_name}`
        }
    },
    data () {
        return {
            date: new Date(),
            timer: null
        }
    },
    mounted () {
        this.timer = setInterval(function () {
            this.date = new Date()
        }.bind(this), 60 * 1000)
    },
    methods: {
        deleteUser () {
            if (this.canDelete && confirm(`Naozaj chcete vymazať používateľa ${this.fullName}?`)) {
                this.$store.dispatch('user/deleteUser', this.user.id)
            }
        },
        expiration (end) {
            const endDate = parseISO(end)
            const expiration = courseExpiration(this.date, endDate, this.$i18n.locale, this.$store.getters['auth/isAdmin'])
    
            if (isAfter(new Date(), endDate)) return `Vypršal pred ${expiration}`
    
            return `Zostáva ${expiration}`
        },
        translate (status) {
            return translateKey(status)
        }
    },
    name: 'UserListTableRow',
    props: {
        user: Object
    }
}
</script>

<style lang="scss" scoped>
.btn {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 42px;
}
</style>
