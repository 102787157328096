import { COURSE_EXPIRATION_EXTENDED, COURSE_EXPIRATION_SHORTENED, COURSE_EXPIRATION_STANDARD } from './course'

/**
 * Translate constant into human-readable.
 *
 * @param {string} key
 * @return {string}
 */
export const translateKey = key => {
    const translations = {
        'active': 'Aktívny',
        'admin': 'Administrátor',
        'inactive': 'Neaktívny',
        'user': 'Používateľ',
        [COURSE_EXPIRATION_SHORTENED]: 'Skrátená',
        [COURSE_EXPIRATION_STANDARD]: 'Štandardná',
        [COURSE_EXPIRATION_EXTENDED]: 'Predĺžená',
    }
    
    return translations[key] ?? `Neznámy preklad (${key})`
}